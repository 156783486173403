import { Currency } from '@pancakeswap/sdk'
import { TokenLogo } from '@pancakeswap/uikit'
// import { useMemo } from 'react'
// import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import styled from 'styled-components'
// import { useHttpLocations } from '@pancakeswap/hooks'
import { BAD_SRCS } from './constants'
// import getTokenLogoURL from '../../utils/getTokenLogoURL'

const StyledLogo = styled(TokenLogo) < { size: string } >`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  // const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  // const srcs: string[] = useMemo(() => {
  //   if (currency?.isNative) return []

  //   if (currency?.isToken) {
  //     const tokenLogoURL = getTokenLogoURL(currency)

  //     if (currency instanceof WrappedTokenInfo) {
  //       if (!tokenLogoURL) return [...uriLocations]
  //       return [...uriLocations, tokenLogoURL]
  //     }
  //     if (!tokenLogoURL) return []
  //     return [tokenLogoURL]
  //   }
  //   return []
  // }, [currency, uriLocations])

  const chainId = currency && currency.chainId ? currency.chainId : 369;
  let currencySymbol = currency && currency.symbol ? currency.symbol : "";

  const currencyLogo = `/images/chains/${chainId}.png`
  if (chainId === 369 && currencySymbol === "WETH") {
    currencySymbol = "WPLS"
  }

  if (currency?.isNative) {
    // if (currency.chainId === ChainDefaultId) {
    //   return <BinanceIcon width={size} style={style} />
    // }
    return (
      <StyledLogo
        badSrcs={BAD_SRCS}
        size={size}
        srcs={[currencyLogo]}
        width={size}
        style={style}
      />
    )
  }

  return (
    <StyledLogo badSrcs={BAD_SRCS} size={size} srcs={[`/images/${chainId}/symbols/${currencySymbol.toLowerCase()}.png`, `/images/symbols/${currencySymbol.toLowerCase()}.png`]} alt={`${currencySymbol ?? 'token'} logo`} style={style} />
  )
}
