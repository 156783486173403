import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | 9INCH',
  defaultTitle: '9INCH',
  description:
    '9inch is the maverick DEX of PulseChain — The home of memecoins, farming, staking and trading. 9inch promotes real DeFi, available for everyone!',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@9inch',
    site: '@9inch',
  },
  openGraph: {
    title: '9INCH DEX on Ethereum and PulseChain',
    description:
      '9inch is the maverick DEX of PulseChain — The home of memecoins, farming, staking and trading. 9inch promotes real DeFi, available for everyone!',
    images: [{ url: 'https://tacofy-swap.web.app/images/swapify.png' }],
  },
}
