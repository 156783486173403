import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 512 512" style={{ borderRadius: '8px' }} {...props}>
      <path d="M400.96 222.809C412.465 197.022 355.591 124.978 301.257 94.9627C267.009 71.7103 231.322 74.9048 224.094 85.1143C208.233 107.52 276.617 126.506 322.352 148.66C312.521 152.945 303.256 160.633 297.808 170.465C280.758 151.788 243.335 135.705 199.424 148.66C169.832 157.391 145.24 177.973 135.735 209.061C133.425 208.031 130.868 207.459 128.178 207.459C117.891 207.459 109.551 215.827 109.551 226.149C109.551 236.471 117.891 244.839 128.178 244.839C130.085 244.839 136.047 243.555 136.047 243.555L231.322 244.248C193.22 304.898 163.108 313.764 163.108 324.271C163.108 334.778 191.919 331.931 202.738 328.014C254.525 309.265 310.147 250.832 319.692 234.011C359.774 239.029 393.459 239.623 400.96 222.809Z" fill="url(#paint0_linear_1758_635)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M322.347 148.664C322.35 148.665 322.352 148.666 322.355 148.667C324.475 147.829 324.132 144.688 323.55 142.221C322.212 136.55 299.128 113.679 277.451 103.435C247.915 89.4763 226.165 90.195 222.949 96.6277C228.965 109.001 256.859 120.618 285.992 132.752C298.421 137.928 311.076 143.198 322.353 148.661C322.351 148.662 322.349 148.663 322.347 148.664Z" fill="url(#paint1_linear_1758_635)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M284.864 273.196C278.89 270.905 272.143 268.803 264.471 266.897C272.651 252.211 274.367 230.47 266.642 216.724C255.801 197.433 242.191 187.165 210.566 187.165C193.172 187.165 146.34 193.044 145.509 232.269C145.422 236.385 145.507 240.157 145.804 243.626L231.322 244.248C219.793 262.599 208.995 276.21 199.542 286.559C210.892 289.477 220.258 291.927 228.857 294.176C237.016 296.311 244.485 298.264 252.301 300.265C264.092 291.646 275.178 282.246 284.864 273.196Z" fill="url(#paint2_linear_1758_635)" />
      <path d="M134.594 239.596C138.087 269.394 154.965 281.072 189.454 284.528C223.942 287.983 243.726 285.665 270.064 288.069C292.061 290.078 311.702 301.325 318.989 297.438C325.546 293.94 321.877 281.302 313.103 273.194C301.729 262.684 285.987 255.377 258.288 252.783C263.808 237.618 262.261 216.355 253.688 204.787C241.292 188.06 218.412 180.498 189.454 183.802C159.199 187.254 130.209 202.199 134.594 239.596Z" fill="url(#paint3_linear_1758_635)" />
      <defs>
        <linearGradient id="paint0_linear_1758_635" x1="195.978" y1="199.973" x2="398.584" y2="257.235" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint1_linear_1758_635" x1="364.383" y1="196.111" x2="217.774" y2="49.6371" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8697FF" />
          <stop offset="1" stopColor="#8697FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_1758_635" x1="288.929" y1="278.315" x2="148.334" y2="197.754" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8697FF" />
          <stop offset="1" stopColor="#8697FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_1758_635" x1="208.067" y1="198.457" x2="303.336" y2="319.099" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.983895" stopColor="#D1D8FF" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;