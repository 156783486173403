import { useCallback, useMemo, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  Menu as UikitMenu,
  NextLinkFromReactRouter,
  footerLinks,
  FlexGap,
  Text,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import { NINEINCH } from '@pancakeswap/tokens'
import { mapChainConfig } from '@pancakeswap/sdk'
import styled from 'styled-components'
import { ChainMap } from 'config/constants/types'
import abiBuyBurn from 'config/abi/NineInchBuyAndBurn.json'
import abiErc20 from 'config/abi/erc20.json'
import useTheme from 'hooks/useTheme'
import { useContract } from 'hooks/useContract'
import { usePhishingBannerManager } from 'state/user/hooks'
import { formatBigNumber } from '@pancakeswap/utils/formatBalance'
import { useTokenBusdPrice } from 'hooks/useBUSDPrice'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { MultiChainName, multiChainName } from 'state/info/constant'
import { fetchTotalLiquidity, fetchDerivedUSDPrice, fetchTotalValueLockedV3 } from 'state/info/queries/pools/poolData'
import { useSingleContractMultiMethods } from 'state/multicall/hooks'
import { ethers } from 'ethers'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { useInterval } from '@pancakeswap/hooks'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const StyledImage = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`

const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const { chainId } = useActiveChainId()
  const { isMobile } = useMatchBreakpoints()
  const cakePriceUsd = useTokenBusdPrice(NINEINCH[chainId]) // usePriceCakeBusd()

  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [showPhishingWarningBanner] = usePhishingBannerManager()
  const [totalLiquidity, setTotalLiquidity] = useState(0)
  const [totalUSDValue, setTotalUSDValue] = useState(0)

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  // useEffect(() => {
  //   const processPoolsForChain = async (currentChainId) => {
  //     const pools = await fetchPoolsTotalStaking(currentChainId)
  //     const poolPromises = pools.map((pool) => {
  //       if (pool.totalStaked !== '0') {
  //         return fetchDerivedUSDPrice(pool.stakingToken.symbol, multiChainName[currentChainId]).then((priceInUSD) => {
  //           return new BigNumber(pool.totalStaked).multipliedBy(priceInUSD.price ?? 0)
  //         })
  //       }
  //       return Promise.resolve(new BigNumber(0))
  //     })

  //     const poolValues = await Promise.all(poolPromises)
  //     return poolValues.reduce((acc, value) => acc.plus(value), new BigNumber(0))
  //   }

  //   const calculateTotalUSDValue = async () => {
  //     const totalForChainETH = await processPoolsForChain(ChainId.ETHEREUM)
  //     const totalForChainPLS = await processPoolsForChain(ChainId.PULSECHAIN)

  //     const totalUSD = totalForChainETH.plus(totalForChainPLS)
  //     setTotalUSDValue(getBalanceNumber(totalUSD, 18))
  //   }

  //   calculateTotalUSDValue().catch(console.error)
  // }, [])

  const formatNumber = (value, precision = 6) => {
    if (!value) return '0'

    const k = 1000
    const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']

    const i = value < 1 ? 0 : Math.floor(Math.log(value) / Math.log(k))

    return `${parseFloat((value / k ** i).toFixed(i ? 2 : precision))}${sizes[i]}`
  }

  const totalVauleLocked = useMemo(() => totalLiquidity + (totalUSDValue ?? 0), [totalLiquidity, totalUSDValue])

  // console.log('TVL: ', tvlPool)
  const ZERO = ethers.BigNumber.from(0)
  const ONE = ethers.utils.parseEther('1')
  const BURNER_ADDRESS: ChainMap<string> = mapChainConfig('NineInchBuyAndBurn')
  const NINEINCH_ADDRESS: ChainMap<string> = mapChainConfig('NineInch')
  const BBC_ADDRESS: ChainMap<string> = mapChainConfig('BBC')

  const contractBurner = useContract(BURNER_ADDRESS[chainId], abiBuyBurn, true)
  const contractNineInch = useContract(NINEINCH_ADDRESS[chainId], abiErc20, true)
  const contractBBC = useContract(BBC_ADDRESS[chainId], abiErc20, true)


  // const deadAddress9InchBalance = await contractNineInch.balanceOf(deadAddress)
  // const deadAddress2InchBalance = await contractNineInch.balanceOf(deadAddress2)
  // const total9inchDeadBalance = deadAddress9InchBalance.add(deadAddress2InchBalance)

  // const deadAddressBBCBalance = await contractBBC.balanceOf(deadAddress)
  // const deadAddress2BBCBalance = await contractBBC.balanceOf(deadAddress2)
  // const total9BBCDeadBalance = deadAddressBBCBalance.add(deadAddress2BBCBalance)


  function useBurnConfig() {
    const deadAddress = "0x000000000000000000000000000000000000dEaD"
    const deadAddress2 = "0x0000000000000000000000000000000000000369";

    const calls = [
      {
        methodName: 'balanceOf',
        inputs: [deadAddress],
      },
      {
        methodName: 'balanceOf',
        inputs: [deadAddress2],
      },
    ]

    const [deadAddress9InchBalance, deadAddress29InchBalance] = useSingleContractMultiMethods(contractNineInch, calls);
    const [deadAddressBBCBalance, deadAddress2BBCBalance] = useSingleContractMultiMethods(contractBBC, calls);

    const total9inchDeadBalance = deadAddress9InchBalance?.result?.[0] ?? ZERO
    const total9inchDeadBalance2 = deadAddress29InchBalance?.result?.[0] ?? ZERO
    const totalBBCDeadBalance = deadAddressBBCBalance?.result?.[0] ?? ZERO
    const totalBBCDeadBalance2 = deadAddress2BBCBalance?.result?.[0] ?? ZERO

    const calls2 = [
      {
        methodName: 'burnedNineInch',
      },
      {
        methodName: 'burnedBBC',
      },
    ]
    const [totalNineInch, totalBBC]: any[] = useSingleContractMultiMethods(contractBurner, calls2)

    return {
      totalNineInch: totalNineInch?.result?.[0].add(total9inchDeadBalance).add(total9inchDeadBalance2) ?? ZERO,
      totalBBC: totalBBC?.result?.[0].add(totalBBCDeadBalance).add(totalBBCDeadBalance2) ?? ZERO,
    }
  }

  function useNineInchConfig() {
    const calls = [
      {
        methodName: 'totalSupply',
      },
    ]
    const [totalSupply]: any[] = useSingleContractMultiMethods(contractNineInch, calls)
    return {
      totalSupply: totalSupply?.result?.[0] ?? ONE,
    }
  }
  function useBBCConfig() {
    const calls = [
      {
        methodName: 'totalSupply',
      },
    ]
    const [totalSupply]: any[] = useSingleContractMultiMethods(contractBBC, calls)
    return {
      totalSupply: totalSupply?.result?.[0] ?? ONE,
    }
  }

  const status = useBurnConfig()
  const NineInchtoken = useNineInchConfig()
  const BBCtoken = useBBCConfig()



  const NineInchBurnedPercentage = status.totalNineInch.mul(100000000).div(NineInchtoken.totalSupply)
  const BBCBurnedPercentage = status.totalBBC.mul(100000000).div(BBCtoken.totalSupply)

  const fetchData = useCallback(() => {
    Promise.all([
      fetchTotalLiquidity(multiChainName[369] as MultiChainName),
      fetchTotalLiquidity(multiChainName[1] as MultiChainName),
      fetchTotalValueLockedV3(multiChainName[369] as MultiChainName),
    ]).then(([plsLiquidity, ethLiquidity, tvlV3]) => {
      const total = plsLiquidity + ethLiquidity + tvlV3
      console.log(plsLiquidity, ethLiquidity, tvlV3)
      setTotalLiquidity(total)
    })
    fetch(`https://stats.9inch.io/get9inchStats`).then(res => res.json()).then((data: any) => {
      const usdPoolStaked: number = Object.values(data).reduce((sum: number, c: any) => {
        return sum + (c.totalPoolsStakedUSD ?? 0) + (c.totalBBCPoolsStakedUSD ?? 0) + (c.totalFarmsLiquidityUSD ?? 0) + (c.totalPrcFarmsLiquidityUSD ?? 0)
      }, 0)
      setTotalUSDValue(usdPoolStaked)
    }).catch(ex => console.error(ex))
  }, [])

  useInterval(fetchData, 1000 * 60)

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        leftSide={
          !isMobile && (
            <>
              <FlexGap alignItems="center" mr="32px" gap="10px">
                <Text color="primary" fontSize="15px">
                  {t('TVL: ')}
                </Text>
                <Text fontSize={14} color="white">
                  ${formatNumber(totalVauleLocked, 2)}
                </Text>
              </FlexGap>
              <FlexGap alignItems="center" mr="32px" gap="10px">
                <StyledImage src="/images/symbols/nineinch.png" alt="nineinchTokenImage" />
                <Text fontSize={14} color="white">
                  {formatBigNumber(NineInchBurnedPercentage, 2, 6)}% 🔥
                </Text>
              </FlexGap>
              <FlexGap alignItems="center" mr="32px" gap="10px">
                <StyledImage src="/images/symbols/bbc.png" alt="bbcTokenImage" />
                <Text fontSize={14} color="white">
                  {formatBigNumber(BBCBurnedPercentage, 2, 6)}% 🔥
                </Text>
              </FlexGap>
            </>
          )
        }
        rightSide={
          <>
            <NetworkSwitcher />
            <UserMenu />

            {/* <div style={{
              height: '50px',
              background:'#170c13',
              margin:'10px',
              paddingTop:'8px',
              paddingRight:'0px',
              paddingLeft:'8px',
              paddingBottom:'5px',
            }}> */}
            {/* <GlobalSettings mode={SettingsMode.GLOBAL} /> */}
            {/* </div> */}
          </>
        }
        banner={showPhishingWarningBanner && typeof window !== 'undefined'}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy BigBonusCoin')}
        buyCakeLink={`/swap?outputCurrency=${NINEINCH[chainId]?.address}`}
        {...props}
      />
    </>
  )
}

export default Menu
