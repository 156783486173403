import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { ChainConfig, ChainDefaultId, ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
}

const getImageUrlFromToken = (token: Token) => {
  const address = token?.isNative ? token.wrapped.address : token.address
  const config = Object.entries(ChainConfig[token.chainId]).find(([_key, val]) => address.toLowerCase() === String(val).toLowerCase())
  // console.log('config', config, token)

  if (config && config[0] && token.chainId === ChainId.PULSECHAIN && config[0] === 'WETH') {
    config[0] = 'WPLS'
  }

  if (config)
    return `/images/symbols/${config[0].toLowerCase()}.png`
  if (token.chainId !== ChainDefaultId) {
    return `/images/${token.chainId}/tokens/${address}.png`
  }
  return `/images/tokens/${address}.png`
}

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && mapping[token.chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${getAddress(
        token.address,
      )}/logo.png`
    }
    return getImageUrlFromToken(token)
  },
  (t) => `${t.chainId}#${t.address}`,
)

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address && chainId && mapping[chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[chainId]}/assets/${getAddress(address)}/logo.png`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL
